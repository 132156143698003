import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ImageFrag = ({ imageData, alt, altText, ...props }) => {
  const image = getImage(imageData.localFile)
  return (
    <GatsbyImage
      alt={alt ? alt : altText ? altText : ''}
      image={image}
      {...props}
    />
  )
}

export default ImageFrag

export const query = graphql`
  fragment ShopifyMediaImageFragment on ShopifyImage {
    src
    altText
    originalSrc
    localFile {
      ino
      childImageSharp {
        gatsbyImageData(aspectRatio: 1, formats: [AUTO, WEBP])
      }
    }
  }
`
